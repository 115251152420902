import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Theme } from '../../utils/theme';
import { GenPlatform, getDefaultImageSize } from '../../utils';
import { ESAIImageShape } from '../generate/enum';

export interface UserPrefsState {
  genPlatform: GenPlatform;
  imageShape: ESAIImageShape;
  imageSize: number;
  parentInfluence: number;
  theme: string;
  viewImageBackground: string;
}

const initialState: UserPrefsState = {
  genPlatform: GenPlatform.SAI,
  imageShape: ESAIImageShape.SQUARE,
  imageSize: getDefaultImageSize(),
  parentInfluence: 85,
  theme: Theme.NEUTRAL,
  viewImageBackground: '#ffffff',
};

export const userPrefsSlice = createSlice({
  name: 'userPrefs',
  initialState,
  reducers: {
    setGenPlatform: (state, action) => {
      state.genPlatform = action.payload;
    },
    setImageShape: (state, action) => {
      state.imageShape = action.payload;
    },
    setImageSize: (state, action) => {
      state.imageSize = action.payload;
    },
    setParentInfluence: (state, action) => {
      state.parentInfluence = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setViewImageBackground: (state, action) => {
      state.viewImageBackground = action.payload;
    },
  },
});

export const {
  setGenPlatform,
  setImageShape,
  setImageSize,
  setParentInfluence,
  setTheme,
  setViewImageBackground,
} = userPrefsSlice.actions;
export const selectGenPlatform = (state: RootState) =>
  state.userPrefs.genPlatform;
export const selectImageShape = (state: RootState) =>
  state.userPrefs.imageShape;
export const selectImageSize = (state: RootState) => state.userPrefs.imageSize;
export const selectParentInfluence = (state: RootState) =>
  state.userPrefs.parentInfluence;
export const selectTheme = (state: RootState) => state.userPrefs.theme;
export const selectViewImageBackground = (state: RootState) =>
  state.userPrefs.viewImageBackground;

export default userPrefsSlice.reducer;
