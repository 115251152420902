import { useParams } from 'react-router-dom';
import { Header } from '../features/header';
import { useAppSelector } from '../app/hooks';
import { selectSiweIsLoggedIn } from '../features/auth/siwe-auth/siwe-auth-slice';
import { selectGauthIsLoggedIn } from '../features/auth/gauth/gauth-slice';
import { RestoreGrid } from '../components';
import { useMutation, useQuery } from '@tanstack/react-query';
import { addContentToCollection, fetchRemovedContent } from '../utils';
import { get } from 'lodash';
import { useState } from 'react';
import { Spinner } from 'flowbite-react';

export const Restore = () => {
  const [restoredContentIds, setRestoredContentIds] = useState<string[]>([]);
  const { collectionId } = useParams<{ collectionId: string }>();
  const isSiweLoggedIn = useAppSelector(selectSiweIsLoggedIn);
  const isGauthLoggedIn = useAppSelector(selectGauthIsLoggedIn);
  const isLoggedIn = isSiweLoggedIn || isGauthLoggedIn;

  // queries
  const { data, isLoading, isError } = useQuery({
    queryKey: ['removedContent', collectionId],
    queryFn: () => fetchRemovedContent(collectionId),
  });

  // mutations
  const addToCollectionMutation = useMutation({
    mutationFn: addContentToCollection,
    // onMutate: () => {},
    // onSuccess: () => {},
    // onError: (error) => {},
  });

  const removedContent = get(data, 'removedContent', []);
  const handleRestore = (contentId: string) => {
    setRestoredContentIds([...restoredContentIds, contentId]);
    addToCollectionMutation.mutate({
      collectionId,
      contentId,
    });
  };

  return (
    <>
      <Header />
      {isLoggedIn && (
        <div className="p-6 lg:p-8 text-white">
          {isLoading && (
            <div className="flex justify-center items-center w-full h-screen">
              <Spinner size="xl" />
            </div>
          )}
          {isError && (
            <div className="flex justify-center items-center w-full h-screen">
              Failed to load content.
            </div>
          )}
          {!isLoading && (
            <RestoreGrid
              removedContent={removedContent}
              restoredContentIds={restoredContentIds}
              onRestore={handleRestore}
            />
          )}
        </div>
      )}
      {!isLoggedIn && (
        <div className="flex justify-center items-center w-full h-screen text-white">
          <span className="p-3">You must be logged in to view this page.</span>
        </div>
      )}
    </>
  );
};
