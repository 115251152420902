import { useState } from 'react';
import {
  ArrowPathRoundedSquareIcon,
  ArrowRightOnRectangleIcon,
  ArrowsPointingInIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CubeTransparentIcon,
  EyeDropperIcon,
} from '@heroicons/react/20/solid';
import { GenerateImageDto } from '../features/generate/types';
import { EGenerateImageType } from '../features/generate/enum';
import { ImageSelectionData, isPhoneRes, scrollToImage } from '../utils';
import {
  forkImageNextColumn,
  generateSessionImagesAsync,
  updateGridStateAsync,
} from '../features/generate/generate-images-slice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { Button, Modal } from 'flowbite-react';
import {
  selectGenPlatform,
  selectImageShape,
  selectImageSize,
  selectParentInfluence,
  setImageSize,
} from '../features/userPrefs/userPrefs-slice';
import { GenerateImageModalProps } from './ViewImageModal';

interface InstrumentBottomDrawerProps {
  canClose: boolean;
  selectedImages: ImageSelectionData[];
  moveModalProps: GenerateImageModalProps;
  clearSelectionCallback: () => void;
}

export const InstrumentBottomDrawer = ({
  canClose,
  selectedImages,
  moveModalProps,
  clearSelectionCallback,
}: InstrumentBottomDrawerProps) => {
  const dispatch = useAppDispatch();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [showSmooshModal, setShowSmooshModal] = useState(false);
  const genPlatform = useAppSelector(selectGenPlatform);
  const imageShape = useAppSelector(selectImageShape);
  const imageSize = useAppSelector(selectImageSize);
  const parentInfluence = useAppSelector(selectParentInfluence);

  const handleGenerateImageClick = (type: EGenerateImageType) => {
    return () => {
      selectedImages.forEach((imageData) => {
        const { displayImage, displayImageList } = imageData;
        const generateDto: GenerateImageDto = {
          prompt: displayImage.content.prompt!,
          parentId: displayImage.content.id,
          colNum: displayImage.colNum,
          type: type,
          dList: displayImageList,
          parentInfluence,
          imageShape,
          genPlatform,
        };
        dispatch(generateSessionImagesAsync(generateDto));
      });
    };
  };

  const buttonClass = isPhoneRes() ? 'h-6 w-6' : 'h-8 w-8';

  return (
    <>
      {drawerOpen && (
        <div className="w-full p-2 md:p-3 bg-instrument-body md:bg-instrument-bottomdrawer md:rounded-b-2xl md:drop-shadow-2xl">
          <div className="flex justify-start md:justify-between gap-2 md:gap-0 items-center w-full text-white">
            <div
              className="flex justify-center items-center active:scale-75 transition-transform instrument-button-medium md:instrument-button-large bg-instrument-bottomdrawer-upcycle hover:bg-white hover:text-instrument-bottomdrawer-upcycle"
              title="Upcycle"
              onClick={handleGenerateImageClick(EGenerateImageType.UPCYCLE)}
            >
              <ArrowPathRoundedSquareIcon className={buttonClass} />
            </div>
            <div
              className="flex justify-center items-center active:scale-75 transition-transform instrument-button-medium md:instrument-button-large bg-instrument-bottomdrawer-mimic hover:bg-white hover:text-instrument-bottomdrawer-mimic"
              title="Mimic"
              onClick={handleGenerateImageClick(EGenerateImageType.MIMIC)}
            >
              <EyeDropperIcon className={buttonClass} />
            </div>
            <div
              className="flex justify-center items-center active:scale-75 transition-transform instrument-button-medium md:instrument-button-large bg-instrument-bottomdrawer-smoosh hover:bg-white hover:text-instrument-bottomdrawer-smoosh"
              title="Smoosh"
              onClick={() => {
                if (selectedImages.length === 2) {
                  dispatch(
                    generateSessionImagesAsync({
                      type: EGenerateImageType.SMOOSH,
                      smooshPromptParentId:
                        selectedImages[0].displayImage.content.id,
                      smooshImageParentId:
                        selectedImages[1].displayImage.content.id,
                      parentInfluence,
                      imageShape,
                      genPlatform,
                    }),
                  );
                  dispatch(
                    generateSessionImagesAsync({
                      type: EGenerateImageType.SMOOSH,
                      smooshPromptParentId:
                        selectedImages[1].displayImage.content.id,
                      smooshImageParentId:
                        selectedImages[0].displayImage.content.id,
                      parentInfluence,
                      imageShape,
                      genPlatform,
                    }),
                  );
                } else {
                  setShowSmooshModal(true);
                }
              }}
            >
              <ArrowsPointingInIcon className={buttonClass} />
            </div>
            <input
              id="minmax-range"
              type="range"
              min={isPhoneRes() ? '64' : '128'}
              max={isPhoneRes() ? '768' : '1024'}
              value={imageSize}
              onChange={(e) => dispatch(setImageSize(parseInt(e.target.value)))}
              className="h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer custom-range-image-size"
            />
            <div
              className={`flex justify-center items-center instrument-button-medium md:instrument-button-large bg-instrument-bottomdrawer-upload hover:bg-white hover:text-instrument-bottomdrawer-upload ${
                isPhoneRes() ? 'ml-auto' : ''
              }`}
              title="Move"
              onClick={() => {
                moveModalProps.setOpenModal({
                  isOpen: true,
                  displayImages: selectedImages.map((img) => img.displayImage),
                });
              }}
            >
              <CubeTransparentIcon className={buttonClass} />
            </div>
            <div
              className={`flex justify-center items-center instrument-button-medium md:instrument-button-large bg-instrument-bottomdrawer-upload hover:bg-white hover:text-instrument-bottomdrawer-upload ${
                isPhoneRes() ? 'ml-auto' : ''
              }`}
              title="Fork"
              onClick={() => {
                selectedImages.forEach((imageData) => {
                  const { displayImage, displayImageList } = imageData;
                  if (displayImageList.length > 1) {
                    dispatch(
                      forkImageNextColumn({
                        dList: displayImageList,
                        image: displayImage,
                      }),
                    );
                  }
                });
                clearSelectionCallback();
                setTimeout(() => {
                  scrollToImage(selectedImages[0].colNum + 1, 0);
                }, 50);
                dispatch(updateGridStateAsync());
              }}
            >
              <ArrowRightOnRectangleIcon className={buttonClass} />
            </div>
          </div>
        </div>
      )}
      {canClose && (
        <div className="flex w-full justify-center">
          <div
            className={`flex w-[3rem] bg-instrument-bottomdrawer justify-center items-center rounded-b-lg text-black cursor-pointer`}
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            {drawerOpen ? (
              <ChevronDownIcon className="w-4 h-4" />
            ) : (
              <ChevronUpIcon className="w-4 h-4" />
            )}
          </div>
        </div>
      )}
      {/* Modal for Smoosh */}
      <Modal
        show={showSmooshModal}
        onClose={() => setShowSmooshModal(false)}
        size="3xl"
        dismissible
      >
        <Modal.Header>Smoosh</Modal.Header>
        <Modal.Body className="flex justify-center px-4">
          <div className="flex justify-center items-center">
            This action requires exactly two images.
          </div>
        </Modal.Body>
        <Modal.Footer className="flex justify-center">
          <Button
            className="w-1/2"
            color="gray"
            onClick={() => setShowSmooshModal(false)}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
