import { useAppSelector } from '../app/hooks';
import { selectGauthIsLoggedIn } from '../features/auth/gauth/gauth-slice';
import { selectSiweIsLoggedIn } from '../features/auth/siwe-auth/siwe-auth-slice';
import { Header } from '../features/header';

export const NotFound = () => {
  const isSiweLoggedIn = useAppSelector(selectSiweIsLoggedIn);
  const isGauthLoggedIn = useAppSelector(selectGauthIsLoggedIn);
  const isLoggedIn = isSiweLoggedIn || isGauthLoggedIn;

  return (
    <div>
      <Header />
      <div className="flex justify-center items-center w-full h-screen text-white">
        <span className="p-3">404 - Page not found.</span>
      </div>
    </div>
  );
};
