import { useEffect, useState } from 'react';
import { Button, Spinner } from 'flowbite-react';
import {
  capitalize,
  filter,
  find,
  get,
  isEmpty,
  matchesProperty,
  sortBy,
  values,
} from 'lodash';
import { Theme } from '../utils/theme';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  selectGenPlatform,
  selectImageShape,
  selectTheme,
  setGenPlatform,
  setImageShape,
  setTheme,
} from '../features/userPrefs/userPrefs-slice';
import { ESAIImageShape } from '../features/generate/enum';
import {
  selectCollectionIdentifier,
  selectInstrument,
  setLoadingInstrument,
  syncSessionCoreAsync,
} from '../features/generate/generate-images-slice';
import { DirectionConfigLite } from './DirectionConfigLite';
import { Loadout } from '../app/types/instrument';
import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid';
import {
  ModifiedSettings,
  SettingsAction,
  SettingsActions,
  SettingsChanges,
  existingLoadoutRemoved,
  getSettingValue,
  getSettingsDiff,
} from '../utils/settings';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createInstrument,
  deleteInstrument,
  fetchInstruments,
  GenPlatform,
  mapDiffToInstrumentPayloads,
  mapInstrumentsToLoadouts,
  updateActiveLoadout,
  updateInstrument,
} from '../utils';
import { Switch } from '@headlessui/react';
import { ModalBase } from './ModalBase';
import {
  PaymentType,
  creditsPaymentUrl,
  fetchPaymentInfo,
} from '../utils/payment';

const Sections = {
  explorer: 'Explorer',
  instrument: 'Instrument',
};

const ExplorerSettings = () => {
  const dispatch = useAppDispatch();
  const currentTheme = useAppSelector(selectTheme);
  const currentCollection = useAppSelector(selectCollectionIdentifier);
  const themeValues = values(Theme);

  // queries
  const {
    data: paymentData,
    isLoading: paymentDataLoading,
    // isError: paymentDataError,
  } = useQuery({
    queryKey: ['paymentInfo'],
    queryFn: fetchPaymentInfo,
  });

  const paymentType = get(paymentData, 'paymentType');
  const credits = get(paymentData, 'credits', 0);
  const requiresCredits = paymentType !== PaymentType.FREE;
  const consultUrl = `${window.location.origin}/consult/${currentCollection.id}`;
  const restoreUrl = `${window.location.origin}/restore/${currentCollection.id}`;

  return (
    <div className="flex flex-col gap-3 w-full">
      <>
        {currentCollection.id && (
          <>
            <label className="text-base font-semibold text-white p-2 rounded-xl bg-slate-900">
              Active Collection
            </label>
            <div className="px-3 py-2 bg-white rounded-md text-sm">
              {currentCollection.name}
            </div>
          </>
        )}
        {currentCollection.id && (
          <div className="flex gap-3 text-white text-sm">
            <div className="px-3 py-2 bg-slate-800 rounded-md">Consult URL</div>
            <a
              href={consultUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="py-2 text-sm text-blue-500"
            >
              {consultUrl}
            </a>
          </div>
        )}
        {currentCollection.id && (
          <div className="flex gap-3 text-white text-sm">
            <div className="px-3 py-2 bg-slate-800 rounded-md">Restore URL</div>
            <a href={restoreUrl} className="py-2 text-sm text-blue-500">
              {restoreUrl}
            </a>
          </div>
        )}
        <label className="text-base font-semibold text-white p-2 rounded-xl bg-slate-900">
          Theme
        </label>
        <select
          id="theme"
          name="theme"
          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
          defaultValue={currentTheme}
          onChange={(e) => dispatch(setTheme(e.target.value))}
        >
          {themeValues.map((theme) => (
            <option key={theme} value={theme}>
              {capitalize(theme)}
            </option>
          ))}
        </select>
        <label className="text-base font-semibold text-white p-2 rounded-xl bg-slate-900">
          Credits
        </label>
        <div
          className={`px-3 py-2 bg-white rounded-md text-sm ${
            !paymentDataLoading && requiresCredits && credits <= 0
              ? 'border-2 border-red-500'
              : ''
          }`}
        >
          {paymentDataLoading ? <Spinner /> : requiresCredits ? credits : '∞'}
        </div>
        <div className="flex gap-3 text-white text-sm">
          <a
            href={creditsPaymentUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="py-2 text-sm text-blue-500"
          >
            {'Click here to get more credits'}
          </a>
        </div>
      </>
    </div>
  );
};

interface InstrumentSettingsProps {
  loadouts: Loadout[];
  onSettingsChange: (settings: ModifiedSettings) => void;
  modifiedSettings: ModifiedSettings;
}

const InstrumentSettings = ({
  loadouts,
  onSettingsChange,
  modifiedSettings,
}: InstrumentSettingsProps) => {
  const dispatch = useAppDispatch();
  const currentGenPlatform = useAppSelector(selectGenPlatform);
  const currentImageShape = useAppSelector(selectImageShape);
  const currentInstrument = useAppSelector(selectInstrument);
  const activeLoadoutId = getSettingValue(
    modifiedSettings,
    'instrument.activeLoadoutId',
    currentInstrument.id || get(loadouts, '[0].id', undefined),
  );
  const [selectedLoadoutId, setSelectedLoadoutId] =
    useState<string>(activeLoadoutId);
  const [selectedDirectionSlot, setSelectedDirectionSlot] =
    useState<number>(-1);

  const imageShapes = [
    ESAIImageShape.SQUARE,
    ESAIImageShape.TALL,
    ESAIImageShape.WIDE,
  ];
  const genPlatforms = [GenPlatform.SAI, GenPlatform.FLUX];
  const newLoadouts = filter(
    modifiedSettings.loadouts,
    (lo) => lo.action === SettingsActions.create,
  );

  const allLoadouts = filter(
    sortBy([...loadouts, ...newLoadouts], ['name']),
    (lo) => !existingLoadoutRemoved(lo.id, modifiedSettings),
  );
  const selectedLoadout =
    find(allLoadouts, matchesProperty('id', selectedLoadoutId)) ||
    allLoadouts[0] ||
    {};
  const isActiveLoadout = selectedLoadout.id === activeLoadoutId;

  useEffect(() => {
    if (isEmpty(loadouts) && isEmpty(newLoadouts)) {
      onSettingsChange(
        getSettingsDiff(undefined, modifiedSettings, {
          action: SettingsActions.create as SettingsAction,
          change: {
            key: SettingsChanges.loadout,
            value: {
              name: 'Default',
              fillDefault: true,
            },
          },
        }),
      );
    }
  }, [loadouts, modifiedSettings, newLoadouts, onSettingsChange]);

  return (
    <div className="flex flex-col gap-3 w-full">
      <label className="text-base font-semibold text-white p-2 rounded-xl bg-slate-900">
        Platform
      </label>
      <div>
        <fieldset>
          <legend className="sr-only">Image shape</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
            {genPlatforms.map((genPlatform) => (
              <div key={genPlatform} className="flex items-center">
                <input
                  id={genPlatform}
                  name="gen-platform"
                  type="radio"
                  checked={currentGenPlatform === genPlatform}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() => dispatch(setGenPlatform(genPlatform))}
                />
                <label
                  htmlFor={genPlatform}
                  className="ml-3 block text-sm font-medium leading-6 text-white"
                >
                  {genPlatform}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
      <label className="text-base font-semibold text-white p-2 rounded-xl bg-slate-900">
        Image Shape
      </label>
      <div>
        <fieldset>
          <legend className="sr-only">Image shape</legend>
          <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
            {imageShapes.map((imageShape) => (
              <div key={imageShape} className="flex items-center">
                <input
                  id={imageShape}
                  name="image-shape"
                  type="radio"
                  checked={currentImageShape === imageShape}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  onChange={() => dispatch(setImageShape(imageShape))}
                />
                <label
                  htmlFor={imageShape}
                  className="ml-3 block text-sm font-medium leading-6 text-white"
                >
                  {imageShape}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
      <label className="text-base font-semibold text-white p-2 rounded-xl bg-slate-900">
        Loadout
      </label>
      <div className="flex justify-between w-full">
        {/* selector */}
        <div className="flex justify-start items-center gap-3">
          <select
            id="theme"
            name="theme"
            className="w-full md:w-[460px] truncate rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={selectedLoadout.id}
            onChange={(e) => {
              setSelectedLoadoutId(e.target.value);
              setSelectedDirectionSlot(-1);
            }}
          >
            {allLoadouts.map((lo) => (
              <option key={lo.id} value={lo.id}>
                {lo.name}
              </option>
            ))}
          </select>
          <Switch
            checked={isActiveLoadout}
            onChange={() => {
              onSettingsChange(
                getSettingsDiff(undefined, modifiedSettings, {
                  action: SettingsActions.update as SettingsAction,
                  change: {
                    key: SettingsChanges.activeLoadoutId,
                    value: selectedLoadout.id,
                  },
                }),
              );
            }}
            className={`${
              isActiveLoadout ? 'bg-indigo-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${
                isActiveLoadout ? 'translate-x-5' : 'translate-x-0'
              } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div>
        {/* controls */}
        <div className="flex justify-end">
          <div
            className={`h-8 w-8 rounded-lg ml-4 ${
              allLoadouts.length === 1 || isActiveLoadout
                ? 'cursor-not-allowed text-gray-400'
                : 'cursor-pointer text-white hover:bg-white hover:text-black'
            }`}
            title="Delete"
            onClick={() => {
              if (allLoadouts.length > 1 && !isActiveLoadout) {
                onSettingsChange(
                  getSettingsDiff(undefined, modifiedSettings, {
                    action: SettingsActions.delete as SettingsAction,
                    change: {
                      key: SettingsChanges.loadout,
                      value: {
                        id: selectedLoadout.id,
                        name: selectedLoadout.name,
                      },
                    },
                  }),
                );

                setSelectedLoadoutId(
                  filter(allLoadouts, (lo) => lo.id !== selectedLoadout.id)[0]
                    .id,
                );
              }
            }}
          >
            <TrashIcon />
          </div>
          <div
            className={`h-8 w-8 text-white rounded-lg ml-4 cursor-pointer hover:bg-white hover:text-black`}
            title="New"
            onClick={() => {
              const loadoutName = `new-${new Date().getTime()}`;
              onSettingsChange(
                getSettingsDiff(undefined, modifiedSettings, {
                  action: SettingsActions.create as SettingsAction,
                  change: {
                    key: SettingsChanges.loadout,
                    value: {
                      name: loadoutName,
                    },
                  },
                }),
              );
              setSelectedLoadoutId(loadoutName);
              setSelectedDirectionSlot(-1);
            }}
          >
            <PlusIcon />
          </div>
        </div>
      </div>
      <DirectionConfigLite
        loadoutId={selectedLoadout.id}
        loadoutName={selectedLoadout.name}
        directions={selectedLoadout.directions}
        onSlotSelect={setSelectedDirectionSlot}
        selectedSlot={selectedDirectionSlot}
        onSettingsChange={onSettingsChange}
        modifiedSettings={modifiedSettings}
      />
    </div>
  );
};

interface SettingsModalProps {
  onClose: () => void;
}

export const SettingsModal = ({ onClose }: SettingsModalProps) => {
  const [modifiedSettings, setModifiedSettings] = useState({});
  const [selectedSection, setSelectedSection] = useState<string>(
    Sections.explorer,
  );
  const dispatch = useAppDispatch();
  const currentCollection = useAppSelector(selectCollectionIdentifier);
  const queryClient = useQueryClient();
  const collectionId = currentCollection.id;

  // queries
  const { data } = useQuery({
    queryKey: ['instruments'],
    queryFn: fetchInstruments,
  });
  const loadouts = mapInstrumentsToLoadouts(data);

  // mutations
  const updateActiveLoadoutMutation = useMutation({
    mutationFn: updateActiveLoadout,
    onSuccess: () => {
      dispatch(syncSessionCoreAsync({ session: undefined }));
    },
  });
  const createInstrumentMutation = useMutation({
    mutationFn: createInstrument,
    onSuccess: () => {
      // invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['instruments'] });
    },
  });
  const updateInstrumentMutation = useMutation({
    mutationFn: updateInstrument,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['instruments'] });
    },
  });
  const deleteInstrumentMutation = useMutation({
    mutationFn: deleteInstrument,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['instruments'] });
    },
  });

  // component action handlers
  const handleSettingsChange = (settings: ModifiedSettings) => {
    setModifiedSettings({ ...settings });
  };
  const handleClose = () => {
    setSelectedSection(Sections.explorer);
    onClose();
  };
  const handleSave = () => {
    const { activeLoadoutId, createLoadout, updateLoadout, deleteLoadout } =
      mapDiffToInstrumentPayloads(loadouts, modifiedSettings, collectionId);

    if (activeLoadoutId) {
      dispatch(setLoadingInstrument(true));
      updateActiveLoadoutMutation.mutate(activeLoadoutId);
    }
    createLoadout?.forEach((instrument) =>
      createInstrumentMutation.mutate(instrument),
    );
    updateLoadout?.forEach((instrument) =>
      updateInstrumentMutation.mutate(instrument),
    );
    deleteLoadout?.forEach((id) => deleteInstrumentMutation.mutate(id));

    setModifiedSettings({});
    handleClose();
  };

  // modal components
  const modalMenu = (
    <div className="flex w-full p-4 gap-4 sticky top-0 bg-slate-700 text-white">
      <div
        className={`flex grow justify-center items-center p-2 rounded-lg cursor-pointer hover:bg-white hover:bg-zinc-500 ${
          selectedSection === Sections.explorer
            ? 'bg-slate-900'
            : 'bg-slate-600'
        }`}
        onClick={() => setSelectedSection(Sections.explorer)}
      >
        Explorer
      </div>
      <div
        className={`flex grow justify-center items-center p-2 rounded-lg cursor-pointer hover:bg-white hover:bg-zinc-500 ${
          selectedSection === Sections.instrument
            ? 'bg-slate-900'
            : 'bg-slate-600'
        }`}
        onClick={() => setSelectedSection(Sections.instrument)}
      >
        Instrument
      </div>
    </div>
  );

  const modalBody = (
    <div className="flex flex-col gap-3">
      {selectedSection === Sections.explorer && <ExplorerSettings />}
      {selectedSection === Sections.instrument && (
        <InstrumentSettings
          loadouts={loadouts}
          onSettingsChange={handleSettingsChange}
          modifiedSettings={modifiedSettings}
        />
      )}
    </div>
  );

  const modalFooter = (
    <>
      <Button className="w-1/2" color="dark" onClick={handleClose}>
        Close
      </Button>
      <Button className="w-1/2" color="blue" onClick={handleSave}>
        Save
      </Button>
    </>
  );

  return (
    <ModalBase
      menu={modalMenu}
      body={modalBody}
      footer={modalFooter}
      title={'Settings'}
      show={true}
      dismissible={false}
      onClose={handleClose}
    />
  );
};
