import { apiAxios } from '../app/axios';

export enum PaymentType {
  FREE = 'FREE',
  CREDITS = 'CREDITS',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export const fetchPaymentInfo = (): Promise<any> =>
  apiAxios.get('/payment/info').then((response) => response.data);

export const creditsPaymentUrl =
  process.env.REACT_APP_STRIPE_CREDITS_PAYMENT_URL;
