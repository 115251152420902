// Generated by @wagmi/cli@1.0.0 on 12/11/2024 at 11:24:02 PM
import { readContract, ReadContractConfig } from 'wagmi/actions'

import {
  useContractRead,
  UseContractReadConfig,
  useContractWrite,
  Address,
  UseContractWriteConfig,
  usePrepareContractWrite,
  UsePrepareContractWriteConfig,
  useContractEvent,
  UseContractEventConfig,
} from 'wagmi'
import {
  ReadContractResult,
  WriteContractMode,
  PrepareWriteContractResult,
} from 'wagmi/actions'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// CKGenesis
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export const ckGenesisABI = [
  { stateMutability: 'nonpayable', type: 'constructor', inputs: [] },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'approved',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'tokenId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
    ],
    name: 'Approval',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'owner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'operator',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      { name: 'approved', internalType: 'bool', type: 'bool', indexed: false },
    ],
    name: 'ApprovalForAll',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'previousOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
      {
        name: 'newOwner',
        internalType: 'address',
        type: 'address',
        indexed: true,
      },
    ],
    name: 'OwnershipTransferred',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      {
        name: 'newID',
        internalType: 'uint256',
        type: 'uint256',
        indexed: false,
      },
    ],
    name: 'TokenMintEvent',
  },
  {
    type: 'event',
    anonymous: false,
    inputs: [
      { name: 'from', internalType: 'address', type: 'address', indexed: true },
      { name: 'to', internalType: 'address', type: 'address', indexed: true },
      {
        name: 'tokenId',
        internalType: 'uint256',
        type: 'uint256',
        indexed: true,
      },
    ],
    name: 'Transfer',
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'TotalCount',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'owner', internalType: 'address', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [{ name: 'data', internalType: 'bytes32', type: 'bytes32' }],
    name: 'bytes32ToString',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'pure',
    type: 'function',
    inputs: [
      { name: 'x', internalType: 'bytes32', type: 'bytes32' },
      { name: 'y', internalType: 'bytes32', type: 'bytes32' },
    ],
    name: 'concatenate',
    outputs: [{ name: '', internalType: 'bytes', type: 'bytes' }],
  },
  {
    stateMutability: 'payable',
    type: 'function',
    inputs: [
      { name: 'receiver', internalType: 'address', type: 'address' },
      { name: 'count', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'createCreepKid',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'getApproved',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'getBalance',
    outputs: [{ name: '', internalType: 'uint256', type: 'uint256' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [
      { name: 'owner', internalType: 'address', type: 'address' },
      { name: 'operator', internalType: 'address', type: 'address' },
    ],
    name: 'isApprovedForAll',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'name',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'owner',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'ownerOf',
    outputs: [{ name: '', internalType: 'address', type: 'address' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'receiver', internalType: 'address', type: 'address' },
      { name: 'count', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'promoMint',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
      { name: '_data', internalType: 'bytes', type: 'bytes' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'operator', internalType: 'address', type: 'address' },
      { name: 'approved', internalType: 'bool', type: 'bool' },
    ],
    name: 'setApprovalForAll',
    outputs: [],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'interfaceId', internalType: 'bytes4', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ name: '', internalType: 'bool', type: 'bool' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [],
    name: 'symbol',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'view',
    type: 'function',
    inputs: [{ name: 'tokenId', internalType: 'uint256', type: 'uint256' }],
    name: 'tokenURI',
    outputs: [{ name: '', internalType: 'string', type: 'string' }],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [
      { name: 'from', internalType: 'address', type: 'address' },
      { name: 'to', internalType: 'address', type: 'address' },
      { name: 'tokenId', internalType: 'uint256', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'newOwner', internalType: 'address', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [],
    name: 'unlock',
    outputs: [],
  },
  {
    stateMutability: 'nonpayable',
    type: 'function',
    inputs: [{ name: 'amount', internalType: 'uint256', type: 'uint256' }],
    name: 'withdraw',
    outputs: [],
  },
] as const

/**
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export const ckGenesisAddress = {
  1: '0x7ef232E01C45377b0321ff11cA50c59C5B69212b',
} as const

/**
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export const ckGenesisConfig = {
  address: ckGenesisAddress,
  abi: ckGenesisABI,
} as const

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Core
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link ckGenesisABI}__.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function readCkGenesis<
  TAbi extends readonly unknown[] = typeof ckGenesisABI,
  TFunctionName extends string = string,
>(
  config: Omit<ReadContractConfig<TAbi, TFunctionName>, 'abi' | 'address'> & {
    chainId?: keyof typeof ckGenesisAddress
  },
) {
  return readContract({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>)
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// React
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ckGenesisABI}__.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisRead<
  TFunctionName extends string,
  TSelectData = ReadContractResult<typeof ckGenesisABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>,
    'abi' | 'address'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractRead({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    ...config,
  } as UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"TotalCount"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisTotalCount<
  TFunctionName extends 'TotalCount',
  TSelectData = ReadContractResult<typeof ckGenesisABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractRead({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'TotalCount',
    ...config,
  } as UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"balanceOf"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisBalanceOf<
  TFunctionName extends 'balanceOf',
  TSelectData = ReadContractResult<typeof ckGenesisABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractRead({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'balanceOf',
    ...config,
  } as UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"bytes32ToString"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisBytes32ToString<
  TFunctionName extends 'bytes32ToString',
  TSelectData = ReadContractResult<typeof ckGenesisABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractRead({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'bytes32ToString',
    ...config,
  } as UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"concatenate"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisConcatenate<
  TFunctionName extends 'concatenate',
  TSelectData = ReadContractResult<typeof ckGenesisABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractRead({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'concatenate',
    ...config,
  } as UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"getApproved"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisGetApproved<
  TFunctionName extends 'getApproved',
  TSelectData = ReadContractResult<typeof ckGenesisABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractRead({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'getApproved',
    ...config,
  } as UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"getBalance"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisGetBalance<
  TFunctionName extends 'getBalance',
  TSelectData = ReadContractResult<typeof ckGenesisABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractRead({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'getBalance',
    ...config,
  } as UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"isApprovedForAll"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisIsApprovedForAll<
  TFunctionName extends 'isApprovedForAll',
  TSelectData = ReadContractResult<typeof ckGenesisABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractRead({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'isApprovedForAll',
    ...config,
  } as UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"name"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisName<
  TFunctionName extends 'name',
  TSelectData = ReadContractResult<typeof ckGenesisABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractRead({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'name',
    ...config,
  } as UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"owner"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisOwner<
  TFunctionName extends 'owner',
  TSelectData = ReadContractResult<typeof ckGenesisABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractRead({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'owner',
    ...config,
  } as UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"ownerOf"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisOwnerOf<
  TFunctionName extends 'ownerOf',
  TSelectData = ReadContractResult<typeof ckGenesisABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractRead({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'ownerOf',
    ...config,
  } as UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"supportsInterface"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisSupportsInterface<
  TFunctionName extends 'supportsInterface',
  TSelectData = ReadContractResult<typeof ckGenesisABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractRead({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'supportsInterface',
    ...config,
  } as UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"symbol"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisSymbol<
  TFunctionName extends 'symbol',
  TSelectData = ReadContractResult<typeof ckGenesisABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractRead({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'symbol',
    ...config,
  } as UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractRead}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"tokenURI"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisTokenUri<
  TFunctionName extends 'tokenURI',
  TSelectData = ReadContractResult<typeof ckGenesisABI, TFunctionName>,
>(
  config: Omit<
    UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractRead({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'tokenURI',
    ...config,
  } as UseContractReadConfig<typeof ckGenesisABI, TFunctionName, TSelectData>)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ckGenesisABI}__.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisWrite<
  TFunctionName extends string,
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof ckGenesisAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ckGenesisABI,
          string
        >['request']['abi'],
        TFunctionName,
        TMode
      > & { address?: Address; chainId?: TChainId }
    : UseContractWriteConfig<typeof ckGenesisABI, TFunctionName, TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
      } = {} as any,
) {
  return useContractWrite<typeof ckGenesisABI, TFunctionName, TMode>({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"approve"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisApprove<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof ckGenesisAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ckGenesisABI,
          'approve'
        >['request']['abi'],
        'approve',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'approve' }
    : UseContractWriteConfig<typeof ckGenesisABI, 'approve', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'approve'
      } = {} as any,
) {
  return useContractWrite<typeof ckGenesisABI, 'approve', TMode>({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'approve',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"createCreepKid"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisCreateCreepKid<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof ckGenesisAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ckGenesisABI,
          'createCreepKid'
        >['request']['abi'],
        'createCreepKid',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'createCreepKid'
      }
    : UseContractWriteConfig<typeof ckGenesisABI, 'createCreepKid', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'createCreepKid'
      } = {} as any,
) {
  return useContractWrite<typeof ckGenesisABI, 'createCreepKid', TMode>({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'createCreepKid',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"promoMint"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisPromoMint<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof ckGenesisAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ckGenesisABI,
          'promoMint'
        >['request']['abi'],
        'promoMint',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'promoMint' }
    : UseContractWriteConfig<typeof ckGenesisABI, 'promoMint', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'promoMint'
      } = {} as any,
) {
  return useContractWrite<typeof ckGenesisABI, 'promoMint', TMode>({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'promoMint',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisRenounceOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof ckGenesisAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ckGenesisABI,
          'renounceOwnership'
        >['request']['abi'],
        'renounceOwnership',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'renounceOwnership'
      }
    : UseContractWriteConfig<
        typeof ckGenesisABI,
        'renounceOwnership',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'renounceOwnership'
      } = {} as any,
) {
  return useContractWrite<typeof ckGenesisABI, 'renounceOwnership', TMode>({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'renounceOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"safeTransferFrom"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisSafeTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof ckGenesisAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ckGenesisABI,
          'safeTransferFrom'
        >['request']['abi'],
        'safeTransferFrom',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'safeTransferFrom'
      }
    : UseContractWriteConfig<typeof ckGenesisABI, 'safeTransferFrom', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'safeTransferFrom'
      } = {} as any,
) {
  return useContractWrite<typeof ckGenesisABI, 'safeTransferFrom', TMode>({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'safeTransferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"setApprovalForAll"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisSetApprovalForAll<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof ckGenesisAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ckGenesisABI,
          'setApprovalForAll'
        >['request']['abi'],
        'setApprovalForAll',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'setApprovalForAll'
      }
    : UseContractWriteConfig<
        typeof ckGenesisABI,
        'setApprovalForAll',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'setApprovalForAll'
      } = {} as any,
) {
  return useContractWrite<typeof ckGenesisABI, 'setApprovalForAll', TMode>({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'setApprovalForAll',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"transferFrom"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisTransferFrom<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof ckGenesisAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ckGenesisABI,
          'transferFrom'
        >['request']['abi'],
        'transferFrom',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'transferFrom'
      }
    : UseContractWriteConfig<typeof ckGenesisABI, 'transferFrom', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferFrom'
      } = {} as any,
) {
  return useContractWrite<typeof ckGenesisABI, 'transferFrom', TMode>({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'transferFrom',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisTransferOwnership<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof ckGenesisAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ckGenesisABI,
          'transferOwnership'
        >['request']['abi'],
        'transferOwnership',
        TMode
      > & {
        address?: Address
        chainId?: TChainId
        functionName?: 'transferOwnership'
      }
    : UseContractWriteConfig<
        typeof ckGenesisABI,
        'transferOwnership',
        TMode
      > & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'transferOwnership'
      } = {} as any,
) {
  return useContractWrite<typeof ckGenesisABI, 'transferOwnership', TMode>({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'transferOwnership',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"unlock"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisUnlock<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof ckGenesisAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ckGenesisABI,
          'unlock'
        >['request']['abi'],
        'unlock',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'unlock' }
    : UseContractWriteConfig<typeof ckGenesisABI, 'unlock', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'unlock'
      } = {} as any,
) {
  return useContractWrite<typeof ckGenesisABI, 'unlock', TMode>({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'unlock',
    ...config,
  } as any)
}

/**
 * Wraps __{@link useContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"withdraw"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisWithdraw<
  TMode extends WriteContractMode = undefined,
  TChainId extends number = keyof typeof ckGenesisAddress,
>(
  config: TMode extends 'prepared'
    ? UseContractWriteConfig<
        PrepareWriteContractResult<
          typeof ckGenesisABI,
          'withdraw'
        >['request']['abi'],
        'withdraw',
        TMode
      > & { address?: Address; chainId?: TChainId; functionName?: 'withdraw' }
    : UseContractWriteConfig<typeof ckGenesisABI, 'withdraw', TMode> & {
        abi?: never
        address?: never
        chainId?: TChainId
        functionName?: 'withdraw'
      } = {} as any,
) {
  return useContractWrite<typeof ckGenesisABI, 'withdraw', TMode>({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'withdraw',
    ...config,
  } as any)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ckGenesisABI}__.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function usePrepareCkGenesisWrite<TFunctionName extends string>(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ckGenesisABI, TFunctionName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return usePrepareContractWrite({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    ...config,
  } as UsePrepareContractWriteConfig<typeof ckGenesisABI, TFunctionName>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"approve"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function usePrepareCkGenesisApprove(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ckGenesisABI, 'approve'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return usePrepareContractWrite({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'approve',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ckGenesisABI, 'approve'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"createCreepKid"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function usePrepareCkGenesisCreateCreepKid(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ckGenesisABI, 'createCreepKid'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return usePrepareContractWrite({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'createCreepKid',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ckGenesisABI, 'createCreepKid'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"promoMint"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function usePrepareCkGenesisPromoMint(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ckGenesisABI, 'promoMint'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return usePrepareContractWrite({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'promoMint',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ckGenesisABI, 'promoMint'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"renounceOwnership"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function usePrepareCkGenesisRenounceOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ckGenesisABI, 'renounceOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return usePrepareContractWrite({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'renounceOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ckGenesisABI, 'renounceOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"safeTransferFrom"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function usePrepareCkGenesisSafeTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ckGenesisABI, 'safeTransferFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return usePrepareContractWrite({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'safeTransferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ckGenesisABI, 'safeTransferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"setApprovalForAll"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function usePrepareCkGenesisSetApprovalForAll(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ckGenesisABI, 'setApprovalForAll'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return usePrepareContractWrite({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'setApprovalForAll',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ckGenesisABI, 'setApprovalForAll'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"transferFrom"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function usePrepareCkGenesisTransferFrom(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ckGenesisABI, 'transferFrom'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return usePrepareContractWrite({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'transferFrom',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ckGenesisABI, 'transferFrom'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"transferOwnership"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function usePrepareCkGenesisTransferOwnership(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ckGenesisABI, 'transferOwnership'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return usePrepareContractWrite({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'transferOwnership',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ckGenesisABI, 'transferOwnership'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"unlock"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function usePrepareCkGenesisUnlock(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ckGenesisABI, 'unlock'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return usePrepareContractWrite({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'unlock',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ckGenesisABI, 'unlock'>)
}

/**
 * Wraps __{@link usePrepareContractWrite}__ with `abi` set to __{@link ckGenesisABI}__ and `functionName` set to `"withdraw"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function usePrepareCkGenesisWithdraw(
  config: Omit<
    UsePrepareContractWriteConfig<typeof ckGenesisABI, 'withdraw'>,
    'abi' | 'address' | 'functionName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return usePrepareContractWrite({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    functionName: 'withdraw',
    ...config,
  } as UsePrepareContractWriteConfig<typeof ckGenesisABI, 'withdraw'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ckGenesisABI}__.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisEvent<TEventName extends string>(
  config: Omit<
    UseContractEventConfig<typeof ckGenesisABI, TEventName>,
    'abi' | 'address'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractEvent({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    ...config,
  } as UseContractEventConfig<typeof ckGenesisABI, TEventName>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ckGenesisABI}__ and `eventName` set to `"Approval"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisApprovalEvent(
  config: Omit<
    UseContractEventConfig<typeof ckGenesisABI, 'Approval'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractEvent({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    eventName: 'Approval',
    ...config,
  } as UseContractEventConfig<typeof ckGenesisABI, 'Approval'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ckGenesisABI}__ and `eventName` set to `"ApprovalForAll"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisApprovalForAllEvent(
  config: Omit<
    UseContractEventConfig<typeof ckGenesisABI, 'ApprovalForAll'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractEvent({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    eventName: 'ApprovalForAll',
    ...config,
  } as UseContractEventConfig<typeof ckGenesisABI, 'ApprovalForAll'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ckGenesisABI}__ and `eventName` set to `"OwnershipTransferred"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisOwnershipTransferredEvent(
  config: Omit<
    UseContractEventConfig<typeof ckGenesisABI, 'OwnershipTransferred'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractEvent({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    eventName: 'OwnershipTransferred',
    ...config,
  } as UseContractEventConfig<typeof ckGenesisABI, 'OwnershipTransferred'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ckGenesisABI}__ and `eventName` set to `"TokenMintEvent"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisTokenMintEventEvent(
  config: Omit<
    UseContractEventConfig<typeof ckGenesisABI, 'TokenMintEvent'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractEvent({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    eventName: 'TokenMintEvent',
    ...config,
  } as UseContractEventConfig<typeof ckGenesisABI, 'TokenMintEvent'>)
}

/**
 * Wraps __{@link useContractEvent}__ with `abi` set to __{@link ckGenesisABI}__ and `eventName` set to `"Transfer"`.
 *
 * [__View Contract on Ethereum Etherscan__](https://etherscan.io/address/0x7ef232E01C45377b0321ff11cA50c59C5B69212b)
 */
export function useCkGenesisTransferEvent(
  config: Omit<
    UseContractEventConfig<typeof ckGenesisABI, 'Transfer'>,
    'abi' | 'address' | 'eventName'
  > & { chainId?: keyof typeof ckGenesisAddress } = {} as any,
) {
  return useContractEvent({
    abi: ckGenesisABI,
    address: ckGenesisAddress[1],
    eventName: 'Transfer',
    ...config,
  } as UseContractEventConfig<typeof ckGenesisABI, 'Transfer'>)
}
