import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { isEmpty, size } from 'lodash';
import './css/dropzone.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { GenerateImageDto } from './types';
import { EGenerateImageType } from './enum';
import { generateSessionImagesAsync } from './generate-images-slice';
import {
  selectGenPlatform,
  selectParentInfluence,
} from '../userPrefs/userPrefs-slice';

interface UploadFormProps {
  onComplete: () => void;
}

export const UploadForm = ({ onComplete }: UploadFormProps) => {
  const [fileName, setFileName] = useState('');
  const [keywords, setKeywords] = useState('');
  const [uploadRequests, setUploadRequests] = useState<GenerateImageDto[]>([]);
  const genPlatform = useAppSelector(selectGenPlatform);
  const parentInfluence = useAppSelector(selectParentInfluence);
  const dispatch = useAppDispatch();

  const getBase64 = async (files: any[]): Promise<GenerateImageDto[]> => {
    let mappedFiles = [] as GenerateImageDto[];
    await Promise.all(
      files.map((file) => {
        return new Promise((resolve, reject) => {
          var reader = new FileReader();
          reader.onload = () => {
            const imageBase64 = (reader.result || '')
              .toString()
              .replace('data:', '')
              .replace(/^.+,/, '');

            const generateDto: GenerateImageDto = {
              type: EGenerateImageType.UPLOAD,
              imageBase64: imageBase64,
              parentInfluence,
              genPlatform,
            };

            mappedFiles.push(generateDto);
            resolve({});
          };
          reader.onerror = (error) => {
            console.log('Error: ', error);
            reject();
          };

          reader.readAsDataURL(file);
        });
      }),
    );
    return mappedFiles;
  };

  const onDrop = async (acceptedFiles: any[]) => {
    if (size(acceptedFiles) > 1) {
      setFileName('multiple files...');
    } else {
      setFileName(acceptedFiles[0].name);
    }

    const mappedFiles = await getBase64(acceptedFiles);
    setUploadRequests(mappedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
    },
    onDrop,
  });

  const handleUpload = () => {
    const uploadRequestsWithKeywords = uploadRequests.map((ur) => ({
      ...ur,
      keywords: keywords,
    }));
    uploadRequestsWithKeywords.forEach((ur) =>
      dispatch(generateSessionImagesAsync(ur)),
    );
    onComplete();
  };

  return (
    <div className={`w-full h-full p-4 bg-slate-200 rounded`}>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {!fileName && (
          <div>
            <p>Drag 'n' drop the image(s) here, or click to select file(s)</p>
            <p>[.png, .jpg, .gif]</p>
          </div>
        )}
        {fileName && (
          <div>
            <ul>
              <li key={fileName}>{fileName}</li>
            </ul>
          </div>
        )}
      </div>
      <div className="mb-8">
        <input
          name="upload-keywords"
          id="upload-keywords"
          className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Type in keywords..."
          value={keywords}
          onChange={(e) => setKeywords(e.target.value)}
        />
      </div>
      <div className="mb-8 text-center">
        Nilor will digest your image and also upload an unmodified version.
      </div>
      <div className="flex justify-center">
        <button
          type="button"
          className={`${
            isEmpty(uploadRequests)
              ? 'bg-gray-300 text-white cursor-not-allowed hover:none'
              : 'bg-slate-600 hover:bg-slate-700'
          } inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          disabled={isEmpty(uploadRequests)}
          onClick={handleUpload}
        >
          Upload
        </button>
      </div>
    </div>
  );
};
