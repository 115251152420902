import discord from '../../../images/discord.svg';
import stripe from '../../../images/stripe.svg';
import { IconWrapper } from '../../../utils';
import { creditsPaymentUrl } from '../../../utils/payment';

export const NoGauth = () => {
  return (
    <div>
      <NoGauthDisplay />
    </div>
  );
};

const features = [
  {
    name: 'Purchase credits to create an account',
    description: {
      html: (
        <div>
          <a
            href={creditsPaymentUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600"
          >
            {'Follow this link.'}
          </a>
        </div>
      ),
    },
    icon: stripe,
  },
  {
    name: 'Find us on Discord 👀',
    description: {
      html: (
        <>
          <div>
            Request Google access in the{' '}
            <a
              href="https://discord.gg/Cz244j9hPA"
              className="text-white dark:text-blue-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              nilor corp Discord&nbsp;
            </a>
            -- or troubleshoot accessing an existing account.
          </div>
        </>
      ),
    },
    icon: discord,
  },
];

export default function NoGauthDisplay() {
  return (
    <div className="flex flex-col items-center bg-zinc-800 mt-60 md:mt-28 p-8">
      <div>
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
          Sorry, site only accessible to authorized Google users!
          <br />
        </h2>
      </div>
      <div>
        <dl className="max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none mt-12">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt className="ml-9 inline-block font-semibold text-white">
                <IconWrapper
                  icon={feature.icon}
                  alt={feature.name}
                  className="absolute left-1 top-1 h-5 w-5 text-indigo-500"
                />
                {feature.name}
              </dt>{' '}
              <dd className="inline">{feature.description.html}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
