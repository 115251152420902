import { useState } from 'react';
import { ModalBase } from './ModalBase';

export interface RestoreGridProps {
  removedContent: any[];
  restoredContentIds: string[];
  onRestore: (contentId: string) => void;
}

export const RestoreGrid = ({
  removedContent,
  restoredContentIds,
  onRestore,
}: RestoreGridProps) => {
  const [viewImageSrc, setViewImageSrc] = useState('');

  const Body = ({ imageSrc }: { imageSrc: string }) => {
    return (
      <div className="flex h-full gap-2 text-white">
        <img
          src={imageSrc}
          alt="fullsize"
          className="w-full h-full object-contain"
        />
      </div>
    );
  };

  const Footer = ({ onClose }: { onClose: () => void }) => {
    return (
      <button
        className="w-full p-2 text-white bg-slate-500 rounded-full hover:bg-slate-600"
        onClick={onClose}
      >
        Close
      </button>
    );
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
        {removedContent &&
          removedContent.map(
            (rc, idx) =>
              !restoredContentIds.includes(rc.id) && (
                <div
                  key={rc.id}
                  className="group relative flex flex-col gap-2 overflow-hidden rounded-lg border border-gray-200 bg-stone-800 p-3"
                >
                  <img
                    src={rc.thumbnailFullUrl}
                    alt={rc.prompt}
                    className="h-[384px] object-contain hover:opacity-75 cursor-pointer"
                    onClick={() => setViewImageSrc(rc.thumbnailFullUrl)}
                  />
                  <p className="text-sm text-gray-300 bg-stone-800 bg-opacity-70 min-h-[160px]">
                    {rc.prompt}
                  </p>
                  <button
                    className="w-full p-2 text-white bg-green-500 rounded-full hover:bg-green-600"
                    onClick={() => onRestore(rc.id)}
                  >
                    Restore
                  </button>
                </div>
              ),
          )}
      </div>
      <ModalBase
        title=""
        body={<Body imageSrc={viewImageSrc} />}
        show={!!viewImageSrc}
        onClose={() => setViewImageSrc('')}
        size="7xl"
      />
    </>
  );
};
