import { useAppSelector } from '../app/hooks';
import { selectGauthIsLoggedIn } from '../features/auth/gauth/gauth-slice';
import { selectSiweIsLoggedIn } from '../features/auth/siwe-auth/siwe-auth-slice';
import { Header } from '../features/header';
import { PartyView } from '../features/party';

export function Party() {
  const isSiweLoggedIn = useAppSelector(selectSiweIsLoggedIn);
  const isGauthLoggedIn = useAppSelector(selectGauthIsLoggedIn);
  const isLoggedIn = isSiweLoggedIn || isGauthLoggedIn;

  return (
    <div>
      <Header />
      {isLoggedIn && <PartyView />}
      {!isLoggedIn && (
        <div className="flex justify-center items-center w-full h-screen text-white">
          <span className="p-3">You must be logged in to view this page.</span>
        </div>
      )}
    </div>
  );
}
