import { default as logo } from '../../images/logo.svg';
import { useNavigate } from 'react-router-dom';

export function AboutNilor() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen bg-zinc-900">
        <header className="text-center py-10">
          <img src={logo} alt="nilor Logo" className="w-48 mx-auto" />
        </header>
        <main className="w-full max-w-4xl mx-auto p-5">
          <section
            id="welcome"
            className="bg-stone-800 shadow-md rounded px-8 pt-6 pb-8 mb-4"
          >
            <h1 className="text-2xl font-bold text-gray-100">
              Welcome to nilor – Latent Space Exploration 🫡
            </h1>
            <p className="text-gray-200 text-base">
              We are building a latent space exploration vehicle. We believe the
              generative AI tool will exists on the spatial computing platform.
              Welcome to the early stages of that platform!
            </p>
          </section>
          <section
            id="about"
            className="bg-stone-800 shadow-md rounded px-8 pt-6 pb-8 mb-4"
          >
            <h2 className="text-xl font-bold text-gray-100">
              About Us – Pioneering in Generative AI
            </h2>
            <p className="text-gray-200 text-base">
              At nilor, we believe in the transformative power of generative AI
              in the creative industry. That is why we are focusing our business
              around enabling studios to become generative AI powered studios.
            </p>
          </section>
          <section
            id="how-it-works"
            className="bg-stone-800 shadow-md rounded px-8 pt-6 pb-8 mb-4"
          >
            <h2 className="text-xl font-bold text-gray-100">How nilor Works</h2>
            <p className="text-gray-200 text-base">
              we stay on top of all the current AI Innovations and make sure
              nilor seamlessly integrates into your creative workflow. Putting
              the creative studio first, we understand the need for breadth,
              depth and collaboration. We put this at your fingertips, explore
              and create with natural human interaction.
              <br></br>
              <br></br>
              We've also pioneered a new technology the Creative Audit Trail
              (CAT) -- it's like GIT for for creatives. Track an idea through
              it's many stages of the journey to production. Who influenced what
              and where. Keep your brand identity cryptographically provable in
              the oncomming AI agent content cocophony.
            </p>
          </section>
          <section
            id="features"
            className="bg-stone-800 shadow-md rounded px-8 pt-6 pb-8 mb-4"
          >
            <h2 className="text-xl font-bold text-gray-100">It's all Yours!</h2>
            <p className="text-gray-200 text-base">
              All creations are private to you and your community, free to use
              as you please. If you want to learn more about this, feel free to
              check out the{' '}
              <a
                href="https://nilor.cool/privacy"
                className="text-white dark:text-blue-500 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                nilor corp privacy policy&nbsp;
              </a>
            </p>
          </section>
          <section
            id="contact"
            className="bg-stone-600 shadow-md rounded px-8 pt-6 pb-8 mb-4"
          >
            <h2 className="text-xl font-bold text-gray-100">
              Get in Touch with nilor
            </h2>
            <p className="text-gray-200 text-base">
              Right now nilor is only accessible through licensing. If you are
              interested in licensing, want to give it a trial, have questions
              or need support? Our team at nilor is here to assist you please
              join{' '}
              <a
                href="https://discord.gg/Cz244j9hPA"
                className="text-white dark:text-blue-500 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                nilor corp Discord&nbsp;
              </a>
              . Also we run a studio! nilor studio -- want to book us? head over
              to{' '}
              <a
                href="http://nilor.studio"
                className="text-white dark:text-blue-500 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://nilor.studio
              </a>
            </p>
          </section>
          <button
            type="button"
            className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 p-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => navigate('/')}
          >
            Already a member? Click here to go to sign in page!
          </button>
        </main>
      </div>
    </>
  );
}
