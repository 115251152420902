import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { DisplayPartyContent } from '../features/party/types';
import { EContentVote } from '../features/vote/enum';
import { ModalBase } from './ModalBase';
import { ContentVoteDto } from '../features/vote/types';
import { ECollectionType } from '../app/enum';
import {
  removePartyContentAsync,
  voteOnPartyContentAsync,
} from '../features/party/party-slice';
import { useAppDispatch } from '../app/hooks';
import { RemoveFromCollectionDto } from '../app/types';
import { useEffect } from 'react';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export interface ViewPartyImageModalProps {
  imageIndex: number;
  onIndexChange: (index: number) => void;
  collection: DisplayPartyContent[];
  collectionId: string;
}

export const ViewPartyImageModal = ({
  imageIndex,
  onIndexChange,
  collection,
  collectionId,
}: ViewPartyImageModalProps) => {
  const partyContent: DisplayPartyContent = collection[imageIndex];
  const { imageData, content } = partyContent;
  const leftNavEnabled = imageIndex > 0;
  const rightNavEnabled = imageIndex < collection.length - 1;

  useEffect(() => {
    const keydownListener = (event: any) => {
      switch (event.key) {
        case 'ArrowLeft':
          if (leftNavEnabled) {
            onIndexChange(imageIndex - 1);
          }
          break;
        case 'ArrowRight':
          if (rightNavEnabled) {
            onIndexChange(imageIndex + 1);
          }
          break;
      }
    };
    document.addEventListener('keydown', keydownListener);

    return () => {
      document.removeEventListener('keydown', keydownListener);
    };
  });

  const LeftNavButton = () => (
    <ChevronLeftIcon
      className={`flex-none w-8 md:w-12 ${
        leftNavEnabled ? 'cursor-pointer' : 'cursor-not-allowed text-slate-500'
      }`}
      onClick={() => {
        if (leftNavEnabled) {
          onIndexChange(imageIndex - 1);
        }
      }}
    />
  );

  const RightNavButton = () => (
    <ChevronRightIcon
      className={`flex-none w-8 md:w-12 ${
        rightNavEnabled ? 'cursor-pointer' : 'cursor-not-allowed text-slate-500'
      }`}
      onClick={() => {
        if (rightNavEnabled) {
          onIndexChange(imageIndex + 1);
        }
      }}
    />
  );

  const Body = ({ imageSrc }: { imageSrc: string }) => {
    return (
      <>
        <div className="flex h-full gap-2 text-white">
          <LeftNavButton />
          <div className="grow">
            <img
              src={imageSrc}
              alt="fullsize"
              className="w-full h-full object-contain"
            />
          </div>
          <RightNavButton />
        </div>
      </>
    );
  };

  const Footer = ({ collectionId }: { collectionId: string }) => {
    const dispatch = useAppDispatch();

    const handleVote = (vote: EContentVote) => {
      const voteRequest: ContentVoteDto = {
        collectionId: collectionId,
        contentId: content.id,
        collectionType: ECollectionType.PARTY,
        vote,
      };
      dispatch(voteOnPartyContentAsync(voteRequest));

      if (rightNavEnabled) {
        onIndexChange(imageIndex + 1);
      }
    };

    return (
      <div className="flex flex-col justify-center items-center gap-2 w-full text-white">
        <p className="text-sm text-gray-300 z-50 bg-stone-800 bg-opacity-70">
          {content.prompt}
        </p>
        <p className="text-base font-medium text-gray-100 flex items-center justify-center border-2 border-gray-300 bg-stone-800 rounded-full w-12 h-12">
          {content.coolRanking}
        </p>
        <div className="flex gap-2">
          <button
            type="button"
            className="relative inline-flex items-center rounded-l-md bg-red-500 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            onClick={() => {
              const removeContent: RemoveFromCollectionDto = {
                collectionId: collectionId,
                contentId: content.id,
                collectionType: ECollectionType.PARTY,
              };
              dispatch(removePartyContentAsync(removeContent));

              if (rightNavEnabled) {
                onIndexChange(imageIndex + 1);
              }
            }}
          >
            delete
          </button>
          {content.explorerVote !== EContentVote.NOT_COOL && (
            <button
              type="button"
              className="relative -ml-px inline-flex items-center bg-orange-300 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              onClick={() => handleVote(EContentVote.NOT_COOL)}
            >
              not cool
            </button>
          )}
          {content.explorerVote !== EContentVote.COOL && (
            <button
              type="button"
              className={classNames(
                content.explorerVote === EContentVote.ULTRA_COOL
                  ? 'rounded-r-md'
                  : '',
                'relative -ml-px inline-flex items-center bg-lime-200 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10',
              )}
              onClick={() => handleVote(EContentVote.COOL)}
            >
              cool
            </button>
          )}
          {content.explorerVote !== EContentVote.ULTRA_COOL && (
            <button
              type="button"
              className="relative -ml-px inline-flex items-center rounded-r-md bg-pink-400 px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              onClick={() => handleVote(EContentVote.ULTRA_COOL)}
            >
              🔥 ULTRA COOL 🔥
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <ModalBase
      title="Party Rankings"
      body={<Body imageSrc={imageData} />}
      footer={<Footer collectionId={collectionId} />}
      show={true}
      onClose={() => onIndexChange(-1)}
      size="7xl"
    />
  );
};
