import { useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { SiweAuth } from '../auth/siwe-auth/siwe-auth';
import { GAuth } from '../auth/gauth/GAuth';
import { default as letter_logo } from '../../images/logo_only_letters_white.svg';
import { selectSiweIsLoggedIn } from '../auth/siwe-auth/siwe-auth-slice';
import { logout, selectGauthIsLoggedIn } from '../auth/gauth/gauth-slice';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useAppDispatch } from '../../app/hooks';
import Emitter from '../../app/emitter';
import { EEvents } from '../../app/enum';
import { useAccount } from 'wagmi';

export const Header = () => {
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const dispatch = useAppDispatch();
  const { isConnected } = useAccount();
  const isSiweLoggedIn = useAppSelector(selectSiweIsLoggedIn);
  const isGauthLoggedIn = useAppSelector(selectGauthIsLoggedIn);

  const handleSignOut = (confirm: boolean) => {
    if (confirm) {
      dispatch(logout());
      Emitter.emit(EEvents.ADDRESS_CHANGED, null);
    }

    setShowSignOutModal(false);
  };
  const handleGauthSignOutClick = () => setShowSignOutModal(true);

  return (
    <>
      <header className="sticky top-0 z-40 bg-zinc-900">
        <nav
          className="flex justify-between items-center p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="mb-4 md:mb-0 md:mr-auto">
            <a href="/">
              <span className="sr-only">nilor</span>
              <img
                className="h-14 w-auto"
                src={letter_logo}
                alt=""
                height={256}
                width={256}
              />
            </a>
          </div>
          {!isGauthLoggedIn && (
            <div className="pt-1 pb-1">
              <SiweAuth />
            </div>
          )}
          {!isSiweLoggedIn && !isConnected && (
            <div className="pt-1 pb-1">
              <GAuth onSignOut={handleGauthSignOutClick} />
            </div>
          )}
        </nav>
      </header>
      <SignOutModal showModal={showSignOutModal} callback={handleSignOut} />
    </>
  );
};

interface SignOutModalProps {
  showModal: boolean;
  callback: (confirm: boolean) => void;
}
export const SignOutModal = ({ showModal, callback }: SignOutModalProps) => {
  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => callback(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Are you sure you want to sign out?
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={() => callback(true)}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => callback(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
