import { DLinkList } from 'd-link-list';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { DisplayImageContent } from '../features/generate/types';
import { ImagePosition } from '../features/generate/generate-images';
import { includes } from 'lodash';
import { isPhoneRes } from './app';

export interface ImageSelectionData {
  displayImage: DisplayImageContent;
  displayImageList: DLinkList<DisplayImageContent>;
  colNum: number;
  rowNum: number;
}

export const getImageData = (
  images: DLinkList<DLinkList<DisplayImageContent>>,
  selectedImages: ImagePosition[],
): ImageSelectionData[] => {
  // @ts-ignore
  const selectedImagesData: ImageSelectionData[] = selectedImages.map((img) => {
    const imageColumn = img ? images.toArray()[img.colNum] : undefined;
    const image = imageColumn ? imageColumn.toArray()[img.rowNum] : undefined;
    return {
      displayImage: image,
      displayImageList: imageColumn,
      colNum: img.colNum,
      rowNum: img.rowNum,
    };
  });

  return selectedImagesData;
};

export const getImageIds = (
  images: DLinkList<DLinkList<DisplayImageContent>>,
): ImagePosition[] => {
  const imageIds: ImagePosition[] = [];

  images.map((imageList, colIndex) => {
    imageList.map((image, rowIndex) => {
      imageIds.push({
        id: image.content.id,
        colNum: colIndex,
        rowNum: rowIndex,
      });
    });
  });

  return imageIds;
};

export const scrollToImage = (columnIndex: number, rowIndex: number) => {
  // scroll to location based on div height * row index and div width * column index
  const targetImage = document.getElementById(
    `image-${columnIndex}-${rowIndex}`,
  );
  if (targetImage) {
    targetImage.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  } else {
    console.log('targetImage is null');
  }
};

export const getImageUrlsById = (
  images: DLinkList<DLinkList<DisplayImageContent>>,
  ids: Array<string | undefined>,
): Array<string | undefined> => {
  const imageUrls: Array<string | undefined> = [];

  images.map((imageList) => {
    imageList.map((image) => {
      if (includes(ids, image.content.id)) {
        // @ts-ignore
        imageUrls.push(image.imageData);
      }
    });
  });

  return imageUrls;
};

export const handleZipDownload = async (
  imageUrls: Array<string | undefined>,
  loadingCallback: (loading: boolean) => void,
) => {
  var zip = new JSZip();

  loadingCallback(true);

  const remoteZips = imageUrls.map(async (url, index) => {
    const response = await fetch(url as string); // making ts happy
    const data = await response.blob();

    zip.file(`image-${index}.png`, data);

    return data;
  });

  Promise.all(remoteZips)
    .then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `nilor_export.zip`);
      });
    })
    .finally(() => {
      loadingCallback(false);
    });
};

export type ImageShape = 'SQUARE' | 'TALL' | 'WIDE';
export enum GenPlatform {
  SAI = 'SAI',
  FLUX = 'FLUX',
}

export const defaultMobileImageSize = 358;
export const defaultDesktopImageSize = 512;
export const getDefaultImageSize = () =>
  isPhoneRes() ? defaultMobileImageSize : defaultDesktopImageSize;
